
import { ValidationObserver } from "vee-validate";
import Vue, { type PropType } from "vue";
import TextInputWithValidate from "~/components/TextInputWithValidate.vue";
import type * as firebaseTypes from "~/types/firebase";
import { type AllSurveys, type EditSurveyInput } from "~/types/survey";

export default Vue.extend({
  name: "EditSurveyModal",

  components: {
    ValidationObserver,
    TextInputWithValidate,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    allSurveys: {
      type: Object as PropType<AllSurveys>,
      required: true,
    },

    survey: {
      type: Object as PropType<firebaseTypes.DocumentDataWithId>,
      required: true,
    },
  },

  data() {
    return {
      editSurveyInput: {
        text: {
          ja: "",
        },
      } as EditSurveyInput,
    };
  },

  watch: {
    survey() {
      this.editSurveyInput.text.ja = this.survey.text.ja;
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-event");
    },

    async editSurvey() {
      const isValid = await (this.$refs.editSurveyObserver as InstanceType<typeof ValidationObserver>).validate();

      if (!isValid) {
        return;
      }

      this.$emit(
        "edit-event",
        this.allSurveys.departmentId,
        this.allSurveys.organId,
        this.allSurveys.approachId,
        this.allSurveys.genreId,
        this.allSurveys.videoId,
        this.survey.id,
        this.editSurveyInput
      );
    },
  },
});
