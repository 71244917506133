
import { ValidationObserver } from "vee-validate";
import Vue from "vue";
import SelectWithValidate from "~/components/SelectWithValidate.vue";
import { FirebaseCollections } from "~/repository";
import type * as firebaseTypes from "~/types/firebase";
import { getSelectOptionArray } from "~/utils/common";

export default Vue.extend({
  name: "SearchSurveyComponent",

  components: {
    ValidationObserver,
    SelectWithValidate,
  },

  data() {
    return {
      selectOptions: {
        departments: [] as firebaseTypes.SelectOption[],
        organs: [] as firebaseTypes.SelectOption[],
        approaches: [] as firebaseTypes.SelectOption[],
        genres: [] as firebaseTypes.SelectOption[],
      },
      selectedId: {
        department: "",
        organ: "",
        approach: "",
        genre: "",
      },
    };
  },

  watch: {
    async "selectedId.department"() {
      this.selectOptions.organs = getSelectOptionArray(
        await new FirebaseCollections.OrganCollection(this.selectedId.department).getOrganData()
      );
    },

    async "selectedId.organ"() {
      this.selectOptions.approaches = getSelectOptionArray(
        await new FirebaseCollections.ApproachCollection(
          this.selectedId.department,
          this.selectedId.organ
        ).getApproachData()
      );
    },

    async "selectedId.approach"() {
      this.selectOptions.genres = getSelectOptionArray(
        await new FirebaseCollections.GenreCollection(
          this.selectedId.department,
          this.selectedId.organ,
          this.selectedId.approach
        ).getGenreData()
      );
    },
  },

  async created() {
    this.selectOptions.departments = getSelectOptionArray(
      await new FirebaseCollections.DepartmentCollection().getDepartmentData()
    );
  },

  methods: {
    async searchSurvey() {
      const isValid = await (this.$refs.searchSurveyObserver as InstanceType<typeof ValidationObserver>).validate();

      if (!isValid) {
        return;
      }

      this.$emit(
        "search-event",
        this.selectedId.department,
        this.selectedId.organ,
        this.selectedId.approach,
        this.selectedId.genre
      );
    },

    showAllSurvey() {
      this.$emit("show-all-event");
    },
  },
});
