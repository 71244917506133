
import Vue from "vue";
import TaskCard from "~/components/others/TaskCard.vue";
import execApplyDisplayPeiorityOfApproach from "~/helper/20220428/execApplyDIsplayPriorityOfApproach";
import execInsertTestFlag from "~/helper/20220430/insertTestFlag";

export default Vue.extend({
  components: {
    TaskCard,
  },
  middleware: ["authenticated", "engineer"],

  methods: {
    async applyDisplayPriority() {
      if (!window.confirm("過去の治療法に対して、表示優先度を加えてよろしいですか？")) {
        return;
      }

      await execApplyDisplayPeiorityOfApproach();
      window.alert("firestoreを更新しました！");
    },
    async applyTestFlagToHospital() {
      if (
        window.confirm(
          "全ての病院にテストフラグを持たせてもいいですか?\nテスト病院として登録されているものはそのままテスト用に、それ以外はisTest=falseとなります"
        )
      ) {
        await execInsertTestFlag();
        window.alert("正常にfirestoreのデータ更新が行われました！");
      }
    },
  },
});
