
import Vue from "vue";
import AddSurveyModal from "~/components/survey/AddSurveyModal.vue";
import DetailSurveyModal from "~/components/survey/DetailSurveyModal.vue";
import EditSurveyModal from "~/components/survey/EditSurveyModal.vue";
import SearchSurveyComponent from "~/components/survey/SearchSurveyComponent.vue";
import { FirebaseCollections, FirebaseDocuments } from "~/repository";
import type * as firebaseTypes from "~/types/firebase";
import { type AddSurveyInput, type EditSurveyInput, type AllSurveys } from "~/types/survey";
import { timestampToDisplay } from "~/utils/common";

export default Vue.extend({
  components: {
    AddSurveyModal,
    DetailSurveyModal,
    EditSurveyModal,
    SearchSurveyComponent,
  },

  middleware: "authenticated",

  data() {
    return {
      user: this.$store.state.user.user as firebaseTypes.StateAdmin,
      isOpenAddModal: false,
      isOpenDetailModal: false,
      isOpenEditModal: false,
      isLoading: false,
      displayData: [] as AllSurveys[],
      headers: [
        {
          text: "アンケート数",
          value: "survey.length",
        },
        {
          text: "動画",
          value: "videoName",
        },
        {
          text: "ジャンル",
          value: "genreName",
        },
        {
          text: "更新日時",
          value: "updateTimestamp",
        },
        {
          text: "詳細",
          value: "details",
        },
      ],
      selectedAllSurveys: {} as AllSurveys,
      selectedSurvey: {} as firebaseTypes.DocumentDataWithId,
    };
  },

  async created() {
    await this.init();
  },

  methods: {
    openAddModal(item: AllSurveys) {
      this.selectedAllSurveys = item;
      this.isOpenAddModal = true;
    },

    closeAddModal() {
      this.isOpenAddModal = false;
      this.isOpenDetailModal = true;
    },

    openDetailModal(item: AllSurveys) {
      this.selectedAllSurveys = item;
      this.isOpenDetailModal = true;
    },

    closeDetailModal() {
      this.isOpenDetailModal = false;
    },

    openEditModal(survey: firebaseTypes.DocumentDataWithId) {
      this.selectedSurvey = survey;
      this.isOpenEditModal = true;
    },

    closeEditModal() {
      this.isOpenEditModal = false;
      this.isOpenDetailModal = true;
    },

    async addSurvey(
      departmentId: string,
      organId: string,
      approachId: string,
      genreId: string,
      videoId: string,
      input: AddSurveyInput
    ) {
      const surveyCollection = new FirebaseCollections.SurveyCollection(
        departmentId,
        organId,
        approachId,
        genreId,
        videoId
      );

      try {
        await surveyCollection.addSurveyData(input);
        window.alert("アンケートが正常に追加されました.");
        const surveys = await timestampToDisplay(await surveyCollection.getSurveyData());
        this.selectedAllSurveys.survey = surveys;
      } catch (e) {
        this.$store.commit("setSystemErrorMessage", e);
      }
      this.isOpenAddModal = false;
      this.isOpenDetailModal = true;
    },

    async editSurvey(
      departmentId: string,
      organId: string,
      approachId: string,
      genreId: string,
      videoId: string,
      surveyId: string,
      input: EditSurveyInput
    ) {
      const surveyDocument = new FirebaseDocuments.SurveyDocument(
        departmentId,
        organId,
        approachId,
        genreId,
        videoId,
        surveyId
      );

      try {
        await surveyDocument.updateSurveyData(input);
        window.alert("正常にアップデートされました. ");
        this.selectedAllSurveys.survey = this.selectedAllSurveys.survey.filter((survey) => survey.id !== surveyId);
        this.selectedAllSurveys.survey.push((await timestampToDisplay([(await surveyDocument.getSurveyData())!]))[0]);
      } catch (e) {
        this.$store.commit("setSystemErrorMessage", e);
      }
      this.isOpenEditModal = false;
      this.isOpenDetailModal = true;
    },

    async deleteSurvey(
      departmentId: string,
      organId: string,
      approachId: string,
      genreId: string,
      videoId: string,
      surveyId: string
    ) {
      const surveyDocument = new FirebaseDocuments.SurveyDocument(
        departmentId,
        organId,
        approachId,
        genreId,
        videoId,
        surveyId
      );
      try {
        await surveyDocument.deleteSurveyData();
        window.alert("正常に削除されました. ");
        this.selectedAllSurveys.survey = this.selectedAllSurveys.survey.filter((el) => el.id !== surveyId);
      } catch (e) {
        this.$store.commit("setSystemErrorMessage", e);
      }
    },

    async searchSurvey(departmentId: string, organId: string, approachId: string, genreId: string) {
      this.displayData = [];
      this.isLoading = true;
      const videoCollection = new FirebaseCollections.VideoCollection(departmentId, organId, approachId, genreId);
      // 全探索したものから抽出しているので全てのdocumentにおいてundefined許容している
      const departmentName = (await new FirebaseDocuments.DepartmentDocument(departmentId).getDepartmentData())!.name
        .ja as string;
      const organName = (await new FirebaseDocuments.OrganDocument(departmentId, organId).getOrganData())!.name
        .ja as string;
      const approachName = (await new FirebaseDocuments.ApproachDocument(
        departmentId,
        organId,
        approachId
      ).getApproachData())!.name.ja as string;
      const genreName = (await new FirebaseDocuments.GenreDocument(
        departmentId,
        organId,
        approachId,
        genreId
      ).getGenreData())!.name.ja as string;
      try {
        const videoData = await videoCollection.getVideoData();
        for (const video of videoData) {
          const surveyCollection = new FirebaseCollections.SurveyCollection(
            departmentId,
            organId,
            approachId,
            genreId,
            video.id
          );
          const survey = await timestampToDisplay(await surveyCollection.getSurveyData());
          const resData: AllSurveys = {
            departmentId,
            departmentName,
            organId,
            organName,
            approachId,
            approachName,
            genreId,
            genreName,
            videoId: video.id,
            videoName: video.name.ja,
            updateTimestamp: survey.length
              ? survey.reduce((a, b) => (a.updateTimestamp > b.updateTimestamp ? a : b)).updateTimestamp
              : " surveys are not found",
            survey,
          };
          this.displayData.push(resData);
        }
      } catch (e) {
        this.$store.commit("setSystemErrorMessage", e);
      }
      this.isLoading = false;
    },

    async init() {
      this.displayData = [];
      const departmentCollection = new FirebaseCollections.DepartmentCollection();
      const departments = await departmentCollection.getDepartmentData();

      this.isLoading = true;
      for (const department of departments) {
        const departmentId = department.id;
        const departmentName = department.name.ja;
        const organCollection = await new FirebaseCollections.OrganCollection(departmentId);
        const organs = await organCollection.getOrganData();
        for (const organ of organs) {
          const organId = organ.id;
          const organName = organ.name.ja;
          const approachCollection = new FirebaseCollections.ApproachCollection(departmentId, organId);
          const approaches = await approachCollection.getApproachData();
          for (const approach of approaches) {
            const approachId = approach.id;
            const approachName = approach.name.ja;
            const genreCollection = new FirebaseCollections.GenreCollection(departmentId, organId, approachId);
            const genres = await genreCollection.getGenreData();
            for (const genre of genres) {
              const genreId = genre.id;
              const genreName = genre.name.ja;
              const videoCollection = new FirebaseCollections.VideoCollection(
                departmentId,
                organId,
                approachId,
                genreId
              );
              const videos = await videoCollection.getVideoData();
              for (const video of videos) {
                const videoId = video.id;
                const videoName = video.name.ja;
                const surveyCollection = new FirebaseCollections.SurveyCollection(
                  departmentId,
                  organId,
                  approachId,
                  genreId,
                  videoId
                );
                const data = await surveyCollection.getSurveyData();
                if (data) {
                  const survey = await timestampToDisplay(data);
                  this.displayData.push({
                    departmentId,
                    departmentName,
                    organId,
                    organName,
                    approachId,
                    approachName,
                    genreId,
                    genreName,
                    videoId,
                    videoName,
                    updateTimestamp: survey.length
                      ? survey.reduce((a, b) => (a.updateTimestamp > b.updateTimestamp ? a : b)).updateTimestamp
                      : " surveys are not found",
                    survey,
                  });
                }
              }
            }
          }
        }
      }
      this.isLoading = false;
    },
  },
});
