
import { extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Vue from "vue";
import TextInputWithValidate from "~/components/TextInputWithValidate.vue";
import materialCard from "~/components/others/Card.vue";
import firebase from "~/plugins/firebase";
import { FirebaseDocuments } from "~/repository";
import { User } from "~/repository/main/user";

extend("required", {
  ...required,
  message: "空欄です.",
});

export default Vue.extend({
  components: {
    ValidationObserver,
    materialCard,
    TextInputWithValidate,
  },

  layout: "Blank",

  data() {
    return {
      id: "",
      email: "",
      password: "",
      errorMessage: "",
    };
  },

  methods: {
    async authenticate() {
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) {
        return;
      }

      this.email = this.id + "@admin.contrea.jp";
      try {
        await firebase.auth().signInWithEmailAndPassword(this.email, this.password);
        const adminDocument = new FirebaseDocuments.AdminDocument(firebase.auth().currentUser!.uid);
        const data = await adminDocument.getAdminData();
        if (!data) {
          return;
        }
        User.setUser({
          ...data,
          email: this.email,
        });
        await this.$store.commit("user/setUser", Object.assign(data, { email: this.email }));
        this.$router.push("/");
      } catch (e) {
        this.$store.commit("setSystemErrorMessage", e);
      }
    },
  },
});
