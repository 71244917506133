
import Vue, { type PropType } from "vue";
import type * as firebaseTypes from "~/types/firebase";
import { type AllSurveys } from "~/types/survey";

export default Vue.extend({
  name: "DetailSurveyModal",

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    allSurveys: {
      type: Object as PropType<AllSurveys>,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-event");
    },

    openAddModal() {
      this.$emit("close-event");
      this.$emit("open-add-modal-event", this.allSurveys);
    },

    openEditModal(survey: firebaseTypes.DocumentDataWithId) {
      this.$emit("close-event");
      this.$emit("open-edit-modal-event", survey);
    },

    deleteSurvey(survey: firebaseTypes.DocumentDataWithId) {
      if (!window.confirm("削除しますか？")) {
        return;
      }

      this.$emit(
        "delete-event",
        this.allSurveys.departmentId,
        this.allSurveys.organId,
        this.allSurveys.approachId,
        this.allSurveys.genreId,
        this.allSurveys.videoId,
        survey.id
      );
    },
  },
});
