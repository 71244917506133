
import { ValidationObserver } from "vee-validate";
import Vue, { type PropType } from "vue";
import TextInputWithValidate from "~/components/TextInputWithValidate.vue";
import { type AddSurveyInput, type AllSurveys } from "~/types/survey";

export default Vue.extend({
  name: "AddSurveyModal",

  components: {
    ValidationObserver,
    TextInputWithValidate,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    allSurveys: {
      type: Object as PropType<AllSurveys>,
      required: true,
    },
  },

  data() {
    return {
      addSurveyInput: {
        text: {
          ja: "",
        },
      } as AddSurveyInput,
    };
  },

  methods: {
    closeModal() {
      this.$emit("close-event");
    },

    async addSurvey() {
      const isValid = await (this.$refs.addSurveyObserver as InstanceType<typeof ValidationObserver>).validate();

      if (!isValid) {
        return;
      }

      this.$emit(
        "add-event",
        this.allSurveys.departmentId,
        this.allSurveys.organId,
        this.allSurveys.approachId,
        this.allSurveys.genreId,
        this.allSurveys.videoId,
        this.addSurveyInput
      );
    },
  },
});
